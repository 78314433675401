import Vue from 'vue'
import Vuex from 'vuex'

import { Http } from 'Utils/http'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        catalog: [],
        featured: [],
        profile: null,
        navigation: [],
        favorites: [],
        alerts: {
            messages: null,
            notifications: null
        },
        user: null,
        motivation: {
            bonus: 0
        }
    },
    getters: {
        user: state => state.user,
        profile: state => state.profile,
        featured: state => state.featured,
        catalog: state => state.catalog,
        navigation: state => state.navigation,
        alerts: state => state.alerts,
        motivation: state => state.motivation,
        favorites: state => state.favorites
    },
    actions: {
        getCatalog: ({ commit }) => {
            const json = document.querySelector('#ui_catalog_api')
            const parsed = JSON.parse(json.innerHTML)
            const catalogItems = parsed.map((el) => {
                const nested = el.SubItems.map(c => ({
                    name: c.Title,
                    link: c.Url,
                    active: c.Selected,
                    badge: c.Badge ? {
                        name: c.Badge.Title,
                        from: c.Badge.GradientFrom,
                        to: c.Badge.GradientTo,
                        fontColor: c.Badge.FontColor
                    } : null
                }))

                return {
                    name: el.Title,
                    link: el.Url,
                    active: el.Selected,
                    icon: el.Icon,
                    collapsed: !el.Selected,
                    items: nested
                }
            })

            commit('setCatalogItems', catalogItems)
        },
        getFeatured: ({ commit }) => {
            const json = document.querySelector('#ui_feature_api')
            const parsed = JSON.parse(json.innerHTML)
            const featuredItems = parsed.map((el) => {
                const nested = el.SubItems.map(c => ({
                    name: c.Title,
                    link: c.Url,
                    active: c.Selected,
                    badge: c.Badge ? {
                        name: c.Badge.Title,
                        from: c.Badge.GradientFrom,
                        to: c.Badge.GradientTo,
                        fontColor: c.Badge.FontColor
                    } : null
                }))

                return {
                    name: el.Title,
                    link: el.Url,
                    active: el.Selected,
                    icon: el.Icon,
                    items: nested
                }
            })

            commit('setFeaturedItems', featuredItems)
        },
        getProfile: ({ commit, getters }) => {
            const json = document.querySelector('#ui_personal_api')
            const parsed = JSON.parse(json.innerHTML)
            const { isEmailConfirmed } = getters.user

            if (!parsed.length) {
                return undefined
            }

            const profile = parsed[0]
            const data = {
                name: profile.Title,
                link: profile.Url,
                active: profile.Selected,
                collapsed: !profile.Selected,
                icon: profile.Icon,
                hasWarning: !isEmailConfirmed
            }

            data.items = profile.SubItems.map((c) => {
                const hasWarning = c.Title === 'Настройки' && !isEmailConfirmed

                return {
                    name: c.Title,
                    link: c.Url,
                    active: c.Selected,
                    hasWarning
                }
            })

            commit('setProfileItem', data)
        },
        getNavigation: async ({ commit }) => {
            const json = document.querySelector('#ui_menu_api')
            const { data } = await Http.get(Url.route('api.tv.context'))
            const hasOnlineWebCast = Boolean(data.onlineWebCast)
            const parsed = JSON.parse(json.innerHTML)
            const navigation = parsed.map((el) => {
                const hasIndicator = Boolean(el.Indicator)
                const hasAttention = (el.ItemKey === 'tv.index' && hasOnlineWebCast) || (el.ItemKey === 'reference' && hasIndicator)
                const children = el.ItemKey === 'specialities' ? 'Specialities' : 'Children'
                const nested = el[children].map(c => ({
                    name: c.Title || c.Name,
                    link: c.Url || '',
                    active: c.Selected,
                    speciality: c.Alias || ''
                }))

                if (el.ItemKey === 'my-office' || el.ItemKey === 'reference') {
                    nested.unshift({
                        name: el.Title,
                        link: el.Url,
                        active: el.Selected
                    })
                }

                return {
                    name: el.Title,
                    link: el.Url,
                    active: el.Selected,
                    items: children === 'Specialities' ? nested.filter(c => c.name !== el.Title) : nested,
                    prevent: children === 'Specialities',
                    alias: el.ItemKey,
                    hasAttention
                }
            })

            commit('setNavigation', navigation)
        },
        getAlerts: ({ commit }) => {
            const json = document.querySelector('#ui_alerts_api')
            const parsed = JSON.parse(json.innerHTML)
            const mapped = parsed.map(el => ({
                name: el.Title,
                link: el.Url,
                active: el.Selected,
                value: el.UnreadCount
            }))
            const messages = mapped.find(el => el.name === 'Сообщения')
            const notifications = mapped.find(el => el.name === 'Уведомления')

            commit('setAlerts', { messages, notifications })
        },
        getUser: ({ commit }) => {
            const json = document.querySelector('#ui_user_api')
            const parsed = JSON.parse(json.innerHTML)
            const userData = Workle.getUserData()
            const user = {
                name: parsed.FirstName,
                id: parsed.Id,
                surname: parsed.LastName,
                avatar: parsed.Avatar.length ? parsed.Avatar.find(el => el.Type === 'default').VirtualPath : null,
                balance: parsed.TotalBalance.toFixed(),
                isEmailConfirmed: !userData.NotificationContacts.Email.length || userData.IsEmailConfirmed,
                showStarterAbTestTarget: userData.AbTests.ShowStarterAbTestTarget === 'Show'
            }

            commit('setUser', user)
        },
        getMotivation: async ({ commit }) => {
            const json = document.querySelector('.j-sale_motivation')

            if (!json) return undefined

            const parsed = JSON.parse(json.innerHTML)
            const motivation = {
                expirationDate: parsed.ExpirationDate,
                minProfit: parsed.MinProfit,
                minRequestCount: parsed.MinRequestCount,
                totalMinProfit: parsed.TotalMinProfit,
                specialityAlias: parsed.SpecialityAlias,
                productName: parsed.ProductName,
                bonus: parsed.Bonus
            }

            commit('setMotivation', motivation)
        },
        getFavorites: async ({ commit }) => {
            const json = document.querySelector('#ui_favorite_api')
            const parsed = JSON.parse(json.innerHTML)
            const favorites = [
                {
                    name: 'Избранное',
                    icon: '#favorite',
                    link: Url.route('promopage.page', { pageAlias: 'favorites'}),
                    items: parsed.map((el) => ({
                        name: el.Name,
                        link: Url.route('fast-products.single', {
                            alias: el.Alias,
                            w_source: 'favorite'
                        })
                    }))
                }
            ]

            commit('setFavorites', favorites)
        }
    },
    mutations: {
        setNavigation: (state, data) => {
            state.navigation = data
        },
        setCatalogItems: (state, data) => {
            state.catalog = data
        },
        setFeaturedItems: (state, data) => {
            state.featured = data
        },
        setProfileItem: (state, data) => {
            state.profile = data
        },
        setAlerts: (state, data) => {
            state.alerts = data
        },
        setUser: (state, data) => {
            state.user = data
        },
        setMotivation: (state, data) => {
            state.motivation = data
        },
        setFavorites: (state, data) => {
            state.favorites = data
        }
    }
})
